import React from "react";
import Logo from "../../assets/images/visios-logo.png";
import "./index.css";
import simpleImage from "../../assets/images/solution.png";
import axios from "axios";
import LoadingGif from "../../assets/images/loadinggif.gif";
import Coco from "../../assets/images/coco.jpg";
import emailjs from "emailjs-com";

function ImageGen() {
  const [imgSrc, setImgSrc] = React.useState(simpleImage);
  const [generatedImage, setGeneratedImage] = React.useState(null);
  const [userInput, setUserInput] = React.useState("");
  const [style, setStyle] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [uploadedImage, setUploadedImage] = React.useState(null);
  const [userEditInput, setUserEdirInput] = React.useState("");
  const [imageFile, setImageFile] = React.useState(null);
  const styles = ["Realistic", "Cartoon", "Anime", "Monochrome", "Painting", "Scratch", "Marketing"];
  const generateImage = async () => {
    // Logic to generate image goes here
    setLoading(true);
    const prompt = style ? `${userInput}, please generate the image in ${style} style` : userInput;
    const getTaskId = await axios.post("/text2image/generate", {prompt: prompt});
    const imageResult = await axios.get(`/text2image/download/${getTaskId.data.task_id}`, {responseType: "arraybuffer"});
    const base64Image = btoa(new Uint8Array(imageResult.data).reduce((data, byte) => data + String.fromCharCode(byte), ""));
    setImgSrc(`data:image/png;base64,${base64Image}`);
    setGeneratedImage(true);
    setLoading(false);
  };

  const handleUserInput = e => {
    setUserInput(e.target.value);
  };

  const handleEditPrompt = e => {
    setUserEdirInput(e.target.value);
  };

  const selectStyle = e => {
    setStyle(e.target.innerText);
  };

  const downloadImage = () => {
    if (!imgSrc) return;
    const link = document.createElement("a");
    link.href = imgSrc;
    link.download = "generated_image.png";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const resizeImage = async imgSrc => {
    const img = new Image();
    img.src = imgSrc;
    await img.decode();

    // Create a canvas to resize the image
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");

    // Set the canvas size to the desired dimensions (e.g., 50% of original size)
    canvas.width = img.width * 0.5;
    canvas.height = img.height * 0.5;

    // Draw the image on the canvas
    context.drawImage(img, 0, 0, canvas.width, canvas.height);

    // Convert canvas back to base64
    return canvas.toDataURL("image/png");
  };

  const shareImage = async () => {
    if (!imgSrc) return;

    try {
      // Resize the image to reduce payload size
      const resizedImgSrc = await resizeImage(imgSrc);

      const templateParams = {
        to_email: "b295590858@gmail.com",
        subject: "Check out this generated image!",
        message: "Here's a resized image I generated using our app.",
        image_url: resizedImgSrc,
      };

      emailjs
        .send("service_26edxjm", "template_92px4n7", templateParams, "iHEFom9bcJh_bwHr4")
        .then(response => {
          console.log("Email sent successfully:", response.status, response.text);
        })
        .catch(error => {
          console.error("Failed to send email:", error);
        });
    } catch (error) {
      console.error("Error resizing or sending image:", error);
    }
  };

  const handleImageUpload = e => {
    const file = e.target.files[0];
    if (file) {
      setImageFile(file);
      const imageUrl = URL.createObjectURL(file);
      setUploadedImage(imageUrl);
      alert("Image uploaded successfully!");
    }
  };

  const generateEditedImage = async () => {
    setLoading(true);
    const formdata = new FormData();
    formdata.append("prompt", userEditInput);
    formdata.append("steps", "10");
    formdata.append("cfg_image", "1.5");
    formdata.append("seed", "42");
    formdata.append("resolution", "1024");
    formdata.append("image", imageFile);
    formdata.append("cfg_text", "7.5");

    const requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    try {
      const response = await fetch("/img2img/", requestOptions);
      const result = await response.blob();
      const imageUrl = URL.createObjectURL(result);
      setImgSrc(imageUrl);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  return (
    <div className="imageContainer">
      <div className="image-header">
        <div className="logo-container">
          <img src={Logo} alt="Logo" className="logo" />
        </div>

        <div className="image-buttons">
          <button className="image-button">History</button>
          <button className="image-button" onClick={shareImage}>
            Share
          </button>
          <button className="image-button save-button" onClick={downloadImage}>
            Save
          </button>
        </div>
      </div>
      <div className="image-body">
        <div className="image-body-left">
          <h2>Generate Image</h2>
          <p>Describe what you'd like to generate:</p>
          <textarea className="image-input" placeholder="Input prompts" onChange={handleUserInput}></textarea>
          <div className="image-style">
            <p>Styles(Optional):</p>
            <div className="style-list">
              <ul>
                {styles.map(styleName => (
                  <li key={styleName} onClick={selectStyle} className={style === styleName ? "selected" : ""}>
                    {styleName}
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <button className="image-generate-button" onClick={generateImage}>
            Generate
          </button>
          <h2>Modify Existing Image</h2>
          <input type="file" onChange={handleImageUpload} />
          {uploadedImage && (
            <div className="uploaded-image-container">
              <p>Uploaded Image:</p>
              <img src={uploadedImage} alt="Uploaded" className="uploaded-image" />
              <p>Describe what you'd like modify the Image:</p>
              <input type="text" placeholder="input prompts" onChange={handleEditPrompt} />
              <button className="image-generate-button" onClick={generateEditedImage}>
                Modify
              </button>
            </div>
          )}
          {generatedImage && <div> Or use generated Image</div>}
        </div>
        <div className="image-body-right">
          <p style={{marginBottom: "16px"}}>Your Image</p>
          <div className="result">
            {/* Placeholder for the generated image */}
            {imgSrc && <img src={imgSrc} alt="Generated" className="generated-image" />}
          </div>
        </div>

        {loading && (
          <div className="loadingScreen">
            <div>
              <img src={LoadingGif} alt="Loading" className="loading-image" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ImageGen;
